import { PrivateSet, Route, Router, Set } from '@redwoodjs/router'

import GlobalLayout from './layouts/GlobalLayout'
import SentryLayout from './layouts/SentryLayout/SentryLayout'
import { useAuth } from 'src/lib/auth'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route path="/login" page={LoginPage} name="login" />
      <PrivateSet unauthenticated="login">
        <Set wrap={SentryLayout}>
          <Set wrap={GlobalLayout}>
            <Route path="/" page={SetupPage} name="home" />
            <Route path="/currency-accounts" page={CurrencyAccountsPage} name="currencyAccounts" />
            <Route path="/settings" page={SettingsPage} name="settings" />
            <Route path="/home" page={SetupPage} name="setup" />
            <Route path="/quotes" page={QuotesPage} name="quotes" />
            <Route path="/invoices" page={InvoicesPage} name="invoices" />
            <Route path="/organization" page={OrganizationPage} name="organization" />
            <Route path="/developer-tools" page={DeveloperToolsPage} name="developer_tools" />
            <Route path="/swap" page={SwapPage} name="swap" />
            <Route path="/integrations" page={IntegrationsPage} name="integrations" />
          </Set>
        </Set>
      </PrivateSet>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
