import { Link, useLocation } from '@redwoodjs/router'

const TabbarButton = ({ children, match }) => {
  const { pathname } = useLocation()
  return (
    <div
      className={
        pathname == match ? 'rounded-md bg-gray-300 p-2 dark:bg-sky-900' : 'p-2'
      }
    >
      <Link
        className="flex aspect-square min-h-[12px] w-12 flex-col items-center justify-center gap-1"
        to={match}
      >
        {children}
      </Link>
    </div>
  )
}

export default TabbarButton
