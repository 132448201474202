import * as Sentry from '@sentry/react'

let dsn = ''
let environment = 'development'
dsn =
  'https://f748353e48f74562ad2d61c62dbfbca8@o4507627178164224.ingest.us.sentry.io/4507701419704320' // HACK! find out why SENTRY_DSN doesnt work in redwood env while the others are ok
environment = process.env.NODE_ENV

Sentry.init({
  dsn,
  environment,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
})

export default Sentry
