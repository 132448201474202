import React, { useEffect } from 'react'

import { useAuth } from 'src/lib/auth'
import Sentry from 'src/lib/sentry'

interface Props {
  children: React.ReactNode
}

const SentryLayout = ({ children }: Props) => {
  const { currentUser } = useAuth()

  useEffect(() => Sentry.setUser(currentUser), [currentUser])

  return <>{children}</>
}

export default SentryLayout
