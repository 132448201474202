import { Link, navigate, routes } from '@redwoodjs/router'

import { IconButton } from '@chakra-ui/react'
import {
  FiPlus,
  FiRepeat,
  FiFileText,
  FiUsers,
  FiTool,
  FiDollarSign,
  FiSettings,
  FiHome,
  FiExternalLink,
} from 'react-icons/fi'
import SidebarButton from './SidebarButton'
import { useState, useEffect } from 'react'

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [logoSrc, setLogoSrc] = useState('xweave_w_logo.png')
  const [logoClass, setLogoClass] = useState('mb-10 mt-10 pl-8 pr-10')

  useEffect(() => {
    if (collapsed) {
      setLogoSrc('logos/xweave_logo.png')
      setLogoClass('mb-10 mt-10 h-12 w-12')
    } else {
      setLogoSrc('xweave_w_logo.png')
      setLogoClass('mb-10 mt-10 pl-8 pr-10')
    }
  }, [collapsed])

  return (
    <div>
      <div
        className={
          collapsed
            ? 'fixed flex h-screen w-24 flex-col border-r-2 border-gray-200 bg-xweave shadow-md dark:border-gray-700 dark:bg-xweave dark:text-white'
            : 'fixed flex h-screen w-64 flex-col border-r-2 border-gray-200 bg-xweave shadow-md dark:border-gray-700 dark:bg-xweave dark:text-white'
        }
      >
        <header className="flex flex-grow-0 flex-col">
          <div
            className="self-center"
            onClick={() => {
              setCollapsed(!collapsed)
            }}
          >
            <img className={logoClass} src={logoSrc} alt="Xweave Logo" />
          </div>
        </header>

        {collapsed ? (
          <>
            <div className="mx-8 flex flex-col gap-8">
              <div className="flex flex-grow-[0.5]"></div>
              <IconButton
                aria-label="Getting Started"
                size="sm"
                icon={<FiHome />}
                onClick={() => {
                  navigate(routes.setup())
                }}
              />
              <IconButton
                aria-label="quotes"
                size="sm"
                icon={<FiDollarSign />}
                onClick={() => {
                  navigate(routes.quotes())
                }}
              />
              <IconButton
                aria-label="invoices"
                size="sm"
                icon={<FiFileText />}
                onClick={() => {
                  navigate(routes.invoices())
                }}
              />
              <div className="flex flex-grow-[0.5]"></div>
              <IconButton
                aria-label="Integrations"
                size="sm"
                icon={<FiPlus />}
                onClick={() => {
                  navigate(routes.integrations())
                }}
              />
              <IconButton
                aria-label="developer-tools"
                size="sm"
                icon={<FiTool />}
                onClick={() => {
                  navigate(routes.developer_tools())
                }}
              />
              <div className="flex flex-grow-[0.5]"></div>
              <IconButton
                aria-label=""
                size="sm"
                icon={<FiRepeat />}
                onClick={() => {
                  navigate(routes.swap())
                }}
              />
              <div className="flex flex-grow-[0.5]"></div>
              <IconButton
                aria-label="home"
                size="sm"
                icon={<FiSettings />}
                onClick={() => {
                  navigate(routes.settings())
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-grow-[0.5]"></div>
            <div className="mx-8 flex flex-grow flex-col gap-4">
              <p className="text-base font-bold text-gray-300">Manage</p>
              <SidebarButton match="/home">
                <IconButton
                  aria-label="Getting Started"
                  size="sm"
                  icon={<FiHome />}
                />
                <Link className="ml-4 text-left text-sm" to={routes.setup()}>
                  Getting Started
                </Link>
              </SidebarButton>
              <SidebarButton match="/organization">
                <IconButton
                  aria-label="organization"
                  size="sm"
                  icon={<FiUsers />}
                />
                <Link
                  className="ml-4 text-left text-sm"
                  to={routes.organization()}
                >
                  Organization
                </Link>
              </SidebarButton>
              <SidebarButton match="/quotes">
                <IconButton
                  aria-label="quotes"
                  size="sm"
                  icon={<FiDollarSign />}
                />
                <Link className="ml-4 text-left text-sm" to={routes.quotes()}>
                  Transactions
                </Link>
              </SidebarButton>
              <SidebarButton match="/invoices">
                <IconButton
                  aria-label="invoices"
                  size="sm"
                  icon={<FiFileText />}
                />
                <Link className="ml-4 text-left text-sm" to={routes.invoices()}>
                  Invoices
                </Link>
              </SidebarButton>
            </div>
            <div className="mx-8 flex flex-grow flex-col gap-4">
              <p className="text-base font-bold text-gray-300">Setup</p>
              <SidebarButton match="/integrations">
                <IconButton
                  aria-label="Integrations"
                  size="sm"
                  icon={<FiPlus />}
                />
                <Link
                  className="ml-4 text-left text-sm"
                  to={routes.integrations()}
                >
                  Integrations
                </Link>
              </SidebarButton>
              <SidebarButton match="/developer-tools">
                <IconButton
                  aria-label="developer-tools"
                  size="sm"
                  icon={<FiTool />}
                />
                <Link
                  className="ml-4 text-left text-sm"
                  to={routes.developer_tools()}
                >
                  Developer Tools
                </Link>
              </SidebarButton>
            </div>
            <div className="mx-8 flex flex-grow flex-col gap-4">
              <p className="text-base font-bold text-gray-300">Tools</p>
              <SidebarButton match="/swap">
                <IconButton aria-label="" size="sm" icon={<FiRepeat />} />
                <Link className="ml-4 text-left text-sm" to={routes.swap()}>
                  Swap
                </Link>
              </SidebarButton>
            </div>
            <div className="flex flex-grow-[10]"></div>
            <div className="mx-8 mb-4 flex items-center text-sm">
              <FiExternalLink className="ml-5 mr-4 text-lg text-gray-300" />
              <a
                href="https://xweave.stoplight.io"
                className="ml-3 text-gray-300 hover:text-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                API Docs
              </a>
            </div>
            <footer className="mx-8 mb-8 flex flex-grow-[0.3] flex-col text-xl">
              <SidebarButton match="/settings">
                <IconButton aria-label="home" size="sm" icon={<FiSettings />} />
                <Link className="ml-5 text-left text-sm" to={routes.settings()}>
                  Settings
                </Link>
              </SidebarButton>
            </footer>
          </>
        )}
      </div>
      <div
        className={
          collapsed
            ? 'ml-2 hidden w-24 bg-slate-600 lg:block'
            : 'ml-2 hidden w-64 bg-slate-600 lg:block'
        }
      ></div>
    </div>
  )
}

export default Sidebar
