import { useEffect, useState } from 'react'

import Sidebar from 'src/components/Sidebar/Sidebar'
import Tabbar from 'src/components/Tabbar/Tabbar'
import { Toaster } from '@redwoodjs/web/dist/toast'

const GlobalLayout = ({ children }) => {
  const [developerMode, setDeveloperMode] = useState(false)
  useEffect(() => {
    const dev = localStorage.getItem('FORTUNE_DEVELOPER_MODE')
    setDeveloperMode(dev == 'true' ? true : false)
  }, [])
  return (
    <div className="flex h-screen flex-col">
      {developerMode && (
        <div className="fixed flex h-6 w-screen justify-end">
          <div className="mr-12 flex rounded-lg bg-orange-300 px-4 text-center font-bold shadow-sm">
            Developer Mode
          </div>
        </div>
      )}
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <div className="flex flex-grow overflow-hidden">
        <div className="hidden lg:block">
          <Sidebar />
        </div>
        <div className="flex-grow overflow-auto">{children}</div>
      </div>
      <div className="z-50 lg:hidden">
        <Tabbar />
      </div>
    </div>
  )
}

export default GlobalLayout
