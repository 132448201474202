import { Icon } from '@chakra-ui/react'
import { AiOutlineHome } from 'react-icons/ai'
import { FiTool } from 'react-icons/fi'
import { FaNetworkWired } from 'react-icons/fa'
import { TbArrowsExchange } from 'react-icons/tb'
import { CgProfile } from 'react-icons/cg'
import { HiOutlineDocumentText } from 'react-icons/hi'
import { BsBuilding } from 'react-icons/bs'
import { IoSettingsOutline } from 'react-icons/io5'
import TabbarButton from './TabbarButton'
import { routes } from '@redwoodjs/router'

const Tabbar = () => {
  return (
    <>
      <nav className="bottom-0 left-0 right-0 flex justify-around border border-t border-gray-200 bg-white/50 p-2 shadow-lg backdrop-blur-lg dark:border-slate-600/60 dark:bg-slate-800/50">
        <TabbarButton match={routes.organization()}>
          <Icon as={BsBuilding} boxSize={6} opacity={0.8}></Icon>
        </TabbarButton>
        <TabbarButton match={routes.quotes()}>
          <Icon as={HiOutlineDocumentText} boxSize={6} opacity={0.8}></Icon>
        </TabbarButton>
        <TabbarButton match={routes.swap()}>
          <Icon as={TbArrowsExchange} boxSize={6} opacity={0.8}></Icon>
        </TabbarButton>
        <TabbarButton match={routes.integrations()}>
          <Icon as={FaNetworkWired} boxSize={6} opacity={0.8}></Icon>
        </TabbarButton>
        <TabbarButton match={routes.developer_tools()}>
          <Icon as={FiTool} boxSize={6} opacity={0.8}></Icon>
        </TabbarButton>
        <TabbarButton match={routes.settings()}>
          <Icon as={IoSettingsOutline} boxSize={6} opacity={0.8}></Icon>
        </TabbarButton>
      </nav>
    </>
  )
}

export default Tabbar
