import { useLocation } from '@redwoodjs/router'

const SidebarButton = ({ children, match }) => {
  const { pathname } = useLocation()
  return (
    <div
      className={
        pathname.includes(match)
          ? 'rounded-md bg-gray-300 font-semibold dark:bg-gray-800'
          : 'rounded-md'
      }
    >
      <div className="m-3">
        <div
          className={
            pathname.includes(match) ? 'text-gray-800' : 'text-gray-300'
          }
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default SidebarButton
